@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

:global {
  .ant-popover-inner-content {
    p {
      margin-bottom: 0.5em;
    }
  }
  .ant-popover-title {
    font-size: 16px;
    padding: 8px 16px;
  }
}

.overviewHeader {
  padding: 16px 16px 0 16px;
  border-bottom: 0;
  & + div {
    margin: 0;
  }
}

.overviewContent {
  margin: 0;
  :global {
    .ant-card-bordered {
      border: none;
    }
    .ant-card-body {
      padding: 28px;
      border-radius: 2px;
      box-shadow: 0 0 10px 0 #dbe3ed;
      border: solid 2px @white;
      background-color: #fafafa;
    }
  }
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  margin: 0 8px 8px 0;
}
.partTitle {
  font-size: 16px;
  font-weight: 500;
  color: @titleBlack;
}

.overviewCard {
  height: 465px;
  :global {
    .ant-card-body {
      padding: 28px;
      height: 100%;
      overflow-y: scroll;
    }
  }
}

.estimateLabel {
  color: @gray;
  background: @blackExtraLight;
}

.summaryTitle {
  font-weight: 500;
  color: @gray;
  margin-bottom: 5px;
}

.summaryItemWrapperDisabled {
  opacity: 0.5;
  .statusTitle {
    span {
      color: @blackMedium;
    }
  }
}

.tilesRow {
  margin: 24px 0;
}

.summaryItemWrapper {
  display: flex;
  text-align: left;
  :global {
    .ant-badge-status {
      width: 100%;
    }
  }
}

.statusTitle {
  width: 185px;
  text-align: left;
  span {
    font-weight: 500;
  }
}

.statusTitleGreen {
  span {
    color: @green;
  }
}

.statusTitleAmber {
  span {
    color: @amber;
  }
}

.statusTitleRed {
  span {
    color: @red;
  }
}

.aircraftMXSummary {
  margin-top: 30px;
}

.aircraftNumberSummary {
  margin-top: 30px;
  width: 100%;
  float: left;
}

.squareNumberSummary {
  position: relative;
  margin-bottom: 16px;
  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}

.goArrow {
  padding-left: 5px;
  margin-top: 2px;
}

.summarySquareContent {
  position: absolute;
  top: 0;
  left: 8px;
  right: 8px;
  bottom: 0;
  padding-top: ~'calc(50% - 34px)';
  padding-left: 8px;
  padding-right: 8px;
  background: @lightGrayExtraLight;
  transition: all 0.2s;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 6px @blackLight;
  }
}

.summaryNumber {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 4px;
  color: @green;
}

.summaryNumberWarning {
  color: @amber;
}

.summaryNumberError {
  color: @red;
}

.summaryText {
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  color: @lighterGray;
}

.mapDateLink {
  box-sizing: border-box;
  margin: 0;
  color: @blackMedium;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: @lightGrayExtraLight;
  border: 1px solid @lightGrayMedium;
  border-radius: 4px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  font-weight: 500;
  &:last-child {
    margin-right: 0;
  }
}

.mapDateLinkActive {
  color: @blue;
  background: @lightBlueLight;
  border-color: @lightBlue;
}

.partSubTitle {
  color: @titleBlack;
  font-size: 14px;
  font-weight: 500;
  margin-right: 8px;
}

.routesDatesSelection {
  display: flex;
  margin-bottom: 6px;
}

.partTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.tripsContainer {
  .headerWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .viewAllButton {
      display: flex;
      height: 26px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.09px;
      color: #126fd6;
      padding: 0 10px;
      .viewAllWrapper {
        display: flex;
        align-items: center;
      }
      img {
        height: 24px;
        width: 7px;
        margin-left: 4px;
      }
    }
  }
  :global {
    .ant-table-placeholder {
      border-bottom: 0;
    }
  }
}

@media screen and (max-width: 1650px) {
  .summaryText {
    font-size: 10px;
  }
}

@media screen and (max-width: 1500px) {
  .squareNumberSummary {
    width: 50%;
  }

  .summarySquareContent {
    padding-top: ~'calc(50% - 34px)';
  }

  .summaryText {
    font-size: 12px;
  }
}

@media screen and (max-width: 1300px) {
  .summaryText {
    font-size: 10px;
  }
}

@media screen and (max-width: @screen-xl) {
  .summarySquareContent {
    padding-top: ~'calc(50% - 34px)';
  }
  .partTitleContainer {
    flex-wrap: wrap;
  }
  .mapCard {
    margin-top: 30px;
  }
}

@media screen and (max-width: @screen-lg) {
  .squareNumberSummary {
    width: 33.333%;
    float: left;
    &::after {
      padding-bottom: 70%;
    }
  }

  .overviewCard {
    height: unset;
  }

  .summarySquareContent {
    padding-top: ~'calc(30% - 25px)';
  }

  .summaryText {
    font-size: 12px;
  }

  .partTitleContainer {
    margin-top: 30px;
  }

  .aircraftMXSummary {
    width: 50%;
    float: left;
    margin-bottom: 40px;
    margin-top: 12px;
  }
}

@media screen and (max-width: @screen-md) {
  .summaryText {
    font-size: 14px;
  }

  .partTitleContainer {
    margin-top: 30px;
  }

  .partTitle {
    width: auto;
  }
}

@media screen and (max-width: @screen-sm) {
  .squareNumberSummary {
    width: 50%;
  }

  .overviewHeader {
    padding-bottom: 16px;
  }

  .aircraftStatusLocation {
    width: 100%;
    float: none;
    padding-right: 0;
  }

  .aircraftMXSummary {
    width: 100%;
    float: none;
    margin-top: 30px;
  }

  .overviewContent {
    margin: -24px 0 0 0;
    :global {
      .ant-card-body {
        padding: 28px;
      }
    }
  }

  .partTitleContainer {
    margin-top: 30px;
    flex-wrap: wrap;
  }

  .routesDatesSelection {
    flex-wrap: wrap;
  }

  .mapDateLink {
    margin-bottom: 5px;
  }

  .summaryText {
    font-size: 12px;
  }

  .partTitle {
    width: 200px;
  }

  .tripsContainer {
    :global {
      .ant-table-wrapper {
        max-width: 100%;
      }
    }
  }
}
