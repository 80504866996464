@import '../../utils/utils.less';
@import '~antd/lib/style/themes/default.less';

.mxCard {
  width: 100%;
  margin-bottom: 16px;
  :global {
    .ant-card-body {
      padding: 0 !important;
      background: transparent;
      border-radius: 4px;
    }
    .ant-card {
      background: transparent;
    }
  }
}

.workpackItem {
  width: 20px;
  margin-left: 8px;
}

.mxDetails {
  padding: 20px 12px;
}

.modalContentWrapper {
  height: auto;
  .modalTitle {
    font-weight: 500;
    color: #242d41;
    padding-bottom: 12px;
  }
  .modalMessage {
    line-height: 1.29;
    color: rgba(36, 45, 65, 0.7);
  }
  .modalButtonWrapper {
    display: flex;
    justify-content: flex-start;
    margin-top: 28px;
    .submitButton {
      margin-right: 12px;
    }
    .cancelButton {
      border-radius: 16px;
    }
  }
}

.cardTitleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px #fff solid;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border-radius: 4px;
  margin: -1px;
  height: 30px;
  width: ~'calc(100% + 2px)';
  padding: 0;
  outline: 0;
  cursor: pointer;
  position: relative;
  -webkit-appearance: none !important; // need to be important to override browser styles
  background: #fcfcfc;
  .cardTag {
    text-transform: uppercase;
    background: @defectBlue;
    color: @white;
    font-weight: 600;
    font-size: 11px;
    width: 40px;
    padding: 8px;
    letter-spacing: -0.07px;
    text-align: center;
    border-radius: 4px 0 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    &.cardTagsch {
      background: @scheduledBlue;
    }
    &.cardTagllp {
      background: @oopBlue;
    }
    &.cardTagoop {
      background: @llpBlue;
    }
    &.cardTagpkg {
      background: @pkgBlue;
    }
  }
  &.cardTitleWrapperExpanded {
    height: auto;
    min-height: 30px;
    .cardTag {
      border-radius: 4px 0 0 0;
    }
  }
  &.cardTitleWrapperOverdue {
    .cardTag {
      background: @redMessage;
    }
  }
  &.cardTitleWrapperCritical {
    .cardTag {
      background: @amberBright;
    }
  }
}

.titleWrapperLeft {
  padding-left: 39px;
}

.titleWrapperLeft,
.titleWrapperRight {
  display: flex;
  align-items: center;
}

.cardTitle,
.cardTitleWithWP {
  display: flex;
  align-items: flex-start;
  letter-spacing: 0;
  color: rgba(36, 45, 65, 0.9);
  margin-left: 8px;
  .cardTitleArea {
    font-weight: 500;
    text-transform: capitalize;
    margin-right: 3px;
    width: 65px;
    flex-shrink: 0;
    height: 100%;
    align-items: flex-start;
  }
  .cardTitleName {
    text-align: left;
    overflow: hidden;
    overflow-wrap: break-word;
  }
}
.cardTitleNameEllipsis,
.cardTitleNameCollapsedEllipsis {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100vw - 1200px);
}
.cardTitleNameCollapsedEllipsis {
  width: calc(100vw - 1060px);
}

.cardTitleWithWP {
  margin-left: 4px;
}

.cardSource {
  font-size: 12px;
  font-weight: 600;
  color: #181818;
  display: flex;
  align-items: center;
  letter-spacing: -0.08px;
  padding-right: 5px;
  border-right: 1px rgba(36, 45, 65, 0.05) solid;
  margin-right: 5px;
  span {
    opacity: 0.7;
  }
  i {
    background: @darkGrey;
    width: 13px;
    height: 13px;
    font-size: 9px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: @white;
    border-radius: 50%;
    margin-right: 3px;
    &.iconBlue {
      background: @brandAccentBlue;
    }
    &.iconAmber {
      background: @amberBright;
    }
  }
}

.cardDates {
  font-size: 12px;
  font-weight: 400;
  color: rgba(36, 45, 65, 0.9);
  letter-spacing: -0.08px;
  display: flex;
  align-items: center;
  width: 140px;
  overflow: hidden;
  span {
    font-weight: 500;
  }
  .textBlue {
    opacity: 1;
    color: @brandAccentBlue;
  }
}

.cardExpand {
  padding: 0 10px;
  cursor: pointer;
  position: relative;
  top: -1px;
}

.mxManage {
  text-align: right;
  padding: 0 12px;
  margin-bottom: 24px;
  :global {
    .ant-btn-link {
      color: #126fd6;
    }
  }
  button {
    font-weight: 500;
  }
}

.mxWorkpacks {
  border-top: 1px @borderGrey solid;
  padding: 16px 0;
  margin: 0 12px;
  margin-bottom: 8px;
  .partTitle {
    font-size: 12px;
    text-transform: uppercase;
    color: @lighterGray;
  }
  .partDetails {
    span {
      font-weight: 500;
      display: inline-block;
      margin-right: 4px;
    }
    a {
      font-weight: 500;
      display: inline-block;
      margin-left: 8px;
      color: @brandBlue;
    }
  }
}

.itemTitle {
  font-weight: 500;
  color: @titleBlack;
  margin-bottom: 0;
}

.itemInfo {
  margin-bottom: 15px;
  border-bottom: 1px @borderGrey dashed;
  padding-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
}

.mxPackageDetails {
  border-top: 1px @borderGrey solid;
  padding: 16px 0;
  margin: 0 12px;
  margin-bottom: 8px;
  .partTitle {
    color: @titleDarkBlack;
    font-weight: 500;
    letter-spacing: -0.09px;
  }
  &.mxPackageDetailsModal {
    margin: 0;
  }
}

.singlePkgItem {
  display: flex;
  align-items: flex-start;
  align-items: center;
  width: 100%;
  margin: 8px 0;
  color: @titleBlack;
  letter-spacing: 0.2px;
  .tagWrapper {
    width: 50px;
    display: flex;
    align-items: center;
  }
  span {
    margin-right: 6px;
    letter-spacing: -0.09px;
    font-weight: 500;
  }
  &.singlePkgItemREMOVED {
    text,
    .referenceSpan {
      background: @redThin;
      color: @redOpaque;
      padding: 0 2px;
    }
  }
  &.singlePkgItemUPDATE {
    text,
    .referenceSpan {
      background: @greenOpaque;
      color: @greenThin;
      padding: 0 2px;
    }
  }
}

.packageIcon {
  margin-right: 3px;
  height: 13px;
  margin-top: 2px;
}
.dropdownButton {
  margin: 0;
  color: @darkGrey;
  font-weight: 400;
  background: transparent;
  :global {
    i.anticon,
    img {
      transition: all 0.4s;
    }
  }
  &:hover {
    color: @brandBlue;
    :global {
      i.anticon,
      img {
        transform: translateX(-2px);
      }
    }
  }
}

.toleranceIcon {
  margin-right: 8px;
}

.itemDropdown {
  box-shadow: 0 0 10px 5px #dbe3ed;
  background-color: @white;
  border-radius: 4px;
  padding: 0;

  .delete {
    background-color: rgba(202, 35, 34, 0.05);
    .deleteButton {
      color: @red;
    }
  }
  .delete:hover {
    color: @red;
    background-color: rgba(202, 35, 34, 0.1);
  }
  :global {
    .ant-dropdown-menu-item {
      padding: 7px 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      line-height: 21px;

      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}

@media screen and (max-width: @screen-xxl) {
  .cardTitleNameEllipsis {
    width: calc(470px - (1600px - 100vw));
  }
  .cardTitleNameCollapsedEllipsis {
    width: calc(500px - (1600px - 100vw));
  }
}

@media screen and (max-width: @screen-xl) {
  .mxTimings {
    margin-top: 16px;
    margin-left: 0;
  }
  .mxWorkpacks {
    .partDetails {
      a {
        display: block;
        width: 100%;
        margin-left: 0;
        margin-top: 5px;
      }
    }
  }
}
@media screen and (max-width: @screen-xl) {
  .cardTitleWrapper {
    flex-wrap: wrap;
    position: relative;
    .cardTag {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .titleWrapperLeft {
    width: 100%;
    padding-left: 40px;
    padding-right: 25px;
  }
  .cardTitle,
  .cardTitleWithWP {
    padding-top: 4px;
    align-items: flex-start;
    text-align: left;
  }
  .titleWrapperRight {
    margin-top: 8px;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 6px;
    padding-left: 50px;
  }
  .cardExpand {
    position: absolute;
    top: 0;
    right: 0;
  }
  .singlePkgItem {
    flex-wrap: wrap;
  }
  .cardTitleNameEllipsis {
    width: calc(285px - (1200px - 100vw));
  }
  .cardTitleNameCollapsedEllipsis {
    width: calc(425px - (1200px - 100vw));
  }
}

@media screen and (max-width: @screen-lg) {
  .itemValue {
    margin-bottom: 10px;
  }
  .cardTitleWithWP {
    .cardTitleNameEllipsis {
      width: calc(265px - (992px - 100vw));
    }
    .cardTitleNameCollapsedEllipsis {
      width: calc(405px - (992px - 100vw));
    }
  }
  .cardTitle {
    .cardTitleNameEllipsis {
      width: calc(285px - (992px - 100vw));
    }
    .cardTitleNameCollapsedEllipsis {
      width: calc(425px - (992px - 100vw));
    }
  }
}

@media screen and (max-width: 1200px) {
  .titleWrapperRight {
    margin: 0;
    .cardSource,
    .cardDates {
      display: none;
    }
  }
}

@media screen and (max-width: @screen-md) {
  .cardTitle,
  .cardTitleWithWP {
    .cardTitleNameEllipsis {
      width: 110px;
      text-align: left;
    }
    .cardTitleNameCollapsedEllipsis {
      width: 250px;
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .cardTitle,
  .cardTitleWithWP {
    .cardTitleNameEllipsis,
    .cardTitleNameCollapsedEllipsis {
      width: 140px;
      text-align: left;
    }
  }
}
