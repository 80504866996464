@import '~antd/lib/style/themes/default.less';

.datePicker {
  width: 200px;
  height: 34px;
  :global {
    .ant-input {
      width: 200px;
      height: 34px;
    }
  }
  div {
    height: 100%;
  }
}

.datePickerSmall {
  width: 140px;
  height: 34px;
  :global {
    .ant-input {
      width: 140px;
      height: 34px;
    }
  }
  div {
    height: 100%;
  }
}

.timePicker {
  height: 34px;
  :global {
    .ant-time-picker-input {
      height: 34px;
    }
  }
}

@media screen and (max-width: 450px) {
  .datePicker {
    :global {
      .ant-input {
        width: 140px;
      }
    }
  }
}
