@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.aircraftCard {
  width: 100%;
}

.loadingWrapper {
  width: 100%;
  height: 330px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.widgetWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .settingsTitle {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    span {
      font-size: 16px;
      color: @overviewSettingsButton;
    }
  }
  .showTotalsButtonWrapper,
  .showTotalsButtonWrapperPadded {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
    margin-bottom: 4px;
    .goArrow {
      margin-left: 4px;
    }
  }
  .showTotalsButtonWrapperPadded {
    margin-bottom: 24px;
  }
}
