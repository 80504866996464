@import '../../utils/utils.less';
/* stylelint-disable length-zero-no-unit */

.workpackDrawerWrapper {
  padding: 33px 35px 35px 45px;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 700px;
  height: 100%;
  border-radius: 5px;
  background-color: rgb(247, 247, 247);
  z-index: inherit;
  input {
    border: 1px solid rgba(36, 45, 65, 0.1);
    padding: 5px;
    border-radius: 2px;
    margin-top: 5px;
  }
}

.sourceWrapper {
  display: flex;
  align-items: center;
}

.workpackInfo {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 30px;
}

.cancelButtonWrapper {
  margin-left: 20px;
}

.editTitleInfo {
  margin: 10px;
}

.addCRSLink {
  button {
    padding: 10px;
    &:hover {
      background-color: transparent;
    }
  }
}

.editButton {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  text-align: start;
}

.formLabel {
  font-weight: 500;
  color: #242d41;
}

.publicLink {
  justify-content: flex-end;
}

.CRSButtonWrapper {
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  .addCRSButton {
    background-color: #126fd6;
    border: none;
    cursor: pointer;
    color: #fff;
    border-radius: 20px;
    padding: 10px 32px;
    outline: none;
  }
}

.rowItem,
.rowItemDescription {
  display: flex;
  flex-direction: column;
  padding-right: 8px;

  &:last-child {
    padding-right: 0;
  }

  .units {
    color: #646464;
  }

  a,
  .statusInfo {
    display: flex;
    align-items: center;

    img {
      margin-right: 0.3em;
    }
  }

  .statusInfo {
    text-transform: capitalize;
    font-weight: 500;
    color: #646464;
  }
}

.publicLinkWrapper {
  color: @brandAccentBlue;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.paginationWrapper {
  margin-right: 50px;
}

.popoverContainer {
  width: 440px;
  background-color: #fafafa;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  border: 2px solid @white;
  text-align: left;
  padding: 15px;
  .imageContainer {
    width: 24px;
    height: 24px;
    border: solid 0.8px #75bd9a;
    background-image: linear-gradient(to bottom, #4fb184, #35b96d);
    border-radius: 50%;
    text-align: center;
    display: inline-block;
  }

  .clipboardText {
    font-weight: 600;
    letter-spacing: 0.06px;
    color: #181818;
    display: inline-block;
    margin-left: 10px;
  }

  .linkText {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.05px;
    color: #9f9f9f;
    margin-top: 10px;
    margin-bottom: 10px;
    word-break: break-all;
  }

  .popoverText {
    opacity: 0.7;
    line-height: 1.57;
    letter-spacing: 0.06px;
    color: #181818;
  }
}

.rowItemDescription {
  grid-column-end: span 3;
}

.formInput {
  height: 39px;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.1);
}

.expandWrapper {
  display: flex;
  align-items: center;
}

.logoText {
  color: #181818;
  font-size: 12px;
  font-weight: 600;
  padding-right: 5px;
  border-right: 1px solid #ebebeb;
}

.outcome {
  margin-left: 5px;
  font-size: 12px;
  color: #181818;
  margin-right: 15px;
  flex-shrink: 0;
}

.expandIcon {
  margin-right: 15px;
}

.defectDetails {
  display: flex;
  flex-direction: column;
  .header {
    font-weight: 500;
    color: #181818;
    margin-bottom: 20px;
  }
}

.wpRowWrapper {
  display: flex;
  .checkbox {
    margin-top: 15px;
    align-self: center;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-image: linear-gradient(to bottom, #7bc88d, #4aa91c);
    display: flex;
    justify-content: center;
    align-items: center;
    color: @white;
    margin-right: 10px;
    flex-shrink: 0;
    .tickIcon {
      svg {
        height: 6px;
        width: 7px;
      }
    }
  }
  .emptyCheckbox {
    width: 26px;
    height: 31px;
  }
}

.wpItemRow,
.wpItemRowExpanded {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  background-color: @whiteMedium40;
  width: 100%;
  border-radius: 5px;
  margin-top: 15px;
  border: 2px solid @white;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
  .itemDesWrapper {
    display: flex;
  }
}
.wpItemRowExpanded {
  border-radius: 5px 5px 0 0;
  border-bottom: 2px solid transparent;
}

.workpackTitle {
  display: flex;
  align-items: center;

  h3 {
    margin-bottom: 0;
    flex-shrink: 0;
  }

  span {
    padding-right: 5px;
    align-self: center;
  }

  button {
    color: @brandAccentBlue;
    width: 10px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    .threeDotMenu {
      height: 17px;
    }
  }
}

.addItemsWrapper {
  margin-top: 40px;
  .selectWrapper {
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    input {
      margin: 0 15px;
    }
  }
}

.bin {
  cursor: pointer;
  margin-top: 15px;
  border: none;
  background-color: transparent;
  img {
    margin-left: 1px;
    &:hover {
      width: 14px;
      margin-left: 0;
    }
  }
}

.dropdownMenu {
  padding: 0;
  :global {
    .ant-dropdown-menu-item {
      padding: 0;
    }
    .ant-dropdown-menu-item:hover {
      background-color: transparent;
    }
  }
}

.dropdownItem {
  width: 100px;
  height: 35px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #fcfcfc;
  border: none;
  &:hover {
    background-color: rgba(18, 111, 214, 0.05);
    color: @brandAccentBlue;
  }
  &:focus {
    border: 1px solid @brandAccentBlue;
    border-radius: 4px 4px 0 0;
    color: @brandAccentBlue;
  }
}
.deleteItem {
  color: #ca2322;
  background-color: rgba(202, 35, 34, 0.05);
  &:hover {
    background-color: rgba(202, 35, 34, 0.1);
    color: #ca2322;
  }
  &:focus {
    background-color: rgba(202, 35, 34, 0.05);
    border: 1px solid #ca2322;
    border-radius: 0 0 4px 4px;
    color: #ca2322;
  }
}

.minorHeading,
.minorHeadingPad {
  color: @black;
  font-weight: 500;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    margin-bottom: 0;
  }

  img {
    height: 1em;
    width: 1em;
  }
}

.minorHeadingPad {
  margin-bottom: 20px;
}

.signaturePadHeading {
  font-weight: 600;
  color: #242d41;
  margin-bottom: 20px;
}

.signatureSubHeading {
  color: rgba(36, 45, 65, 0.7);
  margin-bottom: 15px;
}

.headingGray {
  color: rgba(36, 45, 65, 0.4);
  font-size: 12px;
  text-transform: uppercase;
}

.mxItemLabel {
  color: #242d41;
  font-weight: 500;
  margin-bottom: 12px;
}

.innerLabel {
  font-size: 12px;
  color: rgba(36, 45, 65, 0.4);
}

.card {
  display: flex;
}
.resultListContainer {
  position: relative;
  width: 360px;
  .searchInput {
    width: 360px;
  }
}
.invisiDiv {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
.resultListWrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  max-height: 330px;
  width: 360px;
  overflow: scroll;
  background-color: @white;
  border: 1px solid rgba(36, 45, 65, 0.1);
  margin-left: 15px;
  border-radius: 4px;
  padding: 10px;
  .resultItem {
    cursor: pointer;
    text-align: left;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    border: none;
    background-color: transparent;
    &:hover {
      background-color: #e6f7ff;
    }
    .itemTitle {
      height: 32px;
      line-height: 32px;
      margin: 0 5px;
      width: 168px;
      color: #242d41;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .estDue {
      color: rgba(36, 45, 65, 0.7);
      text-align: end;
      flex-shrink: 0;
    }
  }
}

.selectBox {
  margin-top: 15px;
  height: 34px;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.1);
  padding: 10px;
}

.mxType {
  color: white;
  min-height: 30px;
  height: auto;
  width: 41px;
  border-radius: 5px 0 0 5px;
  font-size: 11px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mxLabel,
.mxLabelExpanded {
  align-self: center;
  margin-left: 12px;
  color: #181818;
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  .chapterName {
    font-weight: 500;
  }
}

.mxLabelExpanded {
  text-overflow: unset;
  white-space: unset;
}

.column {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

.columnNoMarg {
  display: flex;
  flex-direction: column;
}

.defectResolution,
.defectResolutionResolved,
.defectResolutionEdit {
  display: flex;
  flex-direction: column;
  background-color: @whiteMedium40;
  margin-left: 26px;
  padding: 15px;
  border-radius: 0 0 5px 5px;
  border-bottom: 2px solid @white;
  border-left: 2px solid @white;
  border-right: 2px solid @white;
  .header {
    color: #181818;
    font-weight: 500;
    margin-bottom: 15px;
  }
}
.defectResolutionResolved {
  margin-left: 26px;
}
.defectResolutionEdit {
  margin-right: 26px;
}

.capitalise {
  text-transform: capitalize;
}

.MXDetails,
.MXDetailsEdit,
.MXDetailsResolution {
  display: flex;
  flex-direction: column;
  background-color: @whiteMedium40;
  padding: 15px;
  margin-left: 26px;
  border-radius: 0 0 5px 5px;
  border-bottom: 2px solid @white;
  border-left: 2px solid @white;
  border-right: 2px solid @white;
}
.MXDetailsEdit {
  margin-right: 26px;
}
.MXDetailsResolution {
  margin-left: 26px;
  border-radius: 0;
}

.clearTopBorder {
  border-top: 2px solid transparent;
}

.clearBottomBorder {
  border-bottom: 2px solid transparent;
}

.row {
  display: flex;
  justify-content: space-between;
}

.rowUpperMarg {
  display: flex;
  margin-top: 25px;
  align-items: center;
  span {
    font-weight: 500;
    color: #242d41;
    word-break: keep-all;
    .name {
      font-weight: 600;
    }
  }
  img {
    width: 25%;
  }
}

.rowGridSix {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-top: 30px;
  :global {
    .ant-input {
      border: 1px solid rgba(36, 45, 65, 0.1);
      height: 39px;
      border-radius: 2px;
      margin-top: 5px;
    }
  }
}

.rowGridTwo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;
}

.rowGridFour {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.rowGridFive {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.rowGridFourByTwo {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  row-gap: 25px;
}

.rowGridFiveByTwo {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
  row-gap: 10px;
}

.rowPad15 {
  margin-bottom: 15px;
}

.rowPad20 {
  margin-bottom: 20px;
}

.rowPad25 {
  margin-bottom: 25px;
}

.workpackWhiteBox,
.workpackWhiteBoxPad {
  background-color: @whiteMedium40;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding: 20px;
  border-radius: 5px;
  border: 2px solid @white;
  box-shadow: 0 0 10px 0 rgba(219, 227, 237, 0.41);
}

.workpackWhiteBoxPad {
  margin-top: 30px;
}

.workpackItems {
  display: flex;
}

.signaturePad {
  background-color: @white;
  padding: 15px;
  width: 530px;
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.1);
}
.signatureHelper {
  width: 100%;
  margin-top: 8px;
  height: 32px;
  color: @blackMedium60;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clearButton {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 0 15px;
  height: 32px;
}

.crsAcceptance {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.blueButton {
  background-color: @brandAccentBlue;
  border: none;
  color: @white;
  height: 40px;
  border-radius: 20px;
  padding: 0 40px;
  cursor: pointer;
}

.disabledButton {
  cursor: not-allowed;
  opacity: 0.3;
}

.transparentButton {
  height: 40px;
  padding: 0 40px;
  background-color: transparent;
  color: @brandAccentBlue;
  cursor: pointer;
  margin-right: 10px;
  border: none;
}

.attachmentsSection {
  display: flex;
}

.attachmentWrapper {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.attachmentImage {
  height: auto;
  width: 50px;
  overflow-y: hidden;
}

.defectReportedBy,
.defectReportedOn {
  color: #181818;
  font-weight: 500;
  .bold {
    font-weight: 600;
  }
}

.itemTitleWrapper {
  display: flex;
  align-items: center;
}

.colourSquare {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  margin-right: 10px;
}

.toggleWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}

.sigRow {
  display: flex;
}

.signatureWrapper {
  span {
    font-weight: 500;
    color: #242d41;
    word-break: keep-all;
    .name {
      font-weight: 600;
    }
  }
}

.signatureImage {
  width: 50%;
}

.drawerCloseButton {
  position: absolute;
  top: 33px;
  right: 47px;
  z-index: 202;
  .closeIcon {
    height: 12px;
    width: 12px;
  }
}

.textArea {
  border-radius: 2px;
  border: solid 1px rgba(36, 45, 65, 0.1);
}

.iconBlue {
  background-color: @brandAccentBlue;
  color: @white;
  border-radius: 50%;
  margin-right: 3px;
}
.iconAmber {
  background-color: @amberBright;
  color: @white;
  border-radius: 50%;
  margin-right: 3px;
}

@media (max-width: 1000px) {
  .workpackDrawerWrapper {
    padding: 33px 20px 35px 20px;
  }
}

@media (max-width: 800px) {
  .mxLabel {
    max-width: 350px;
  }
}
