@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.mxItemRow {
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.checkboxWrapper {
  padding-top: 5px;
  margin-right: 12px;
}

:global {
  .ant-tooltip-inner {
    text-align: center;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #126fd6;
    border-color: #126fd6;
    &:hover {
      border-color: #126fd6;
    }
  }
  .ant-checkbox-checked::after {
    border: 1px solid #126fd6;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #126fd6;
  }
}

@media screen and (max-width: @screen-sm) {
  .checkboxWrapper {
    padding-top: 0;
  }
}
