@import '~antd/lib/style/themes/default.less';
@import '../../../utils/utils.less';

.modal {
  :global {
    .ant-modal-body {
      background-color: @offWhite;
    }
  }
}

.headerWrapper {
  padding-bottom: 20px;
  .title {
    color: @titleBlack;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 0;
  }
  .subTitle {
    font-size: 14px;
    color: @blackMedium60;
    font-weight: 400;
  }
}

.footerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  .modalFooterCancelButton {
    border-radius: 15px;
    padding-left: 20px;
    padding-right: 20px;
    height: 30px;
    margin-left: 20px;
    font-weight: 500;
    color: @brandAccentBlue;
  }
}

.cancelButtonWrapper {
  margin-left: 20px;
}

.editableDetailsForm {
  margin-bottom: 15px;
  .editableCellInput {
    text-transform: uppercase;
  }
  .apuFormItem {
    :global {
      .ant-form-item-control-wrapper {
        .ant-form-item-control {
          .ant-form-item-children {
            div {
              height: 34px;
            }
          }
        }
      }
    }
  }
  :global {
    .ant-form-explain {
      display: none;
    }
    .ant-form-item {
      margin: 10px 0 5px 0;
      .ant-form-item-control-wrapper {
        .ant-form-item-children {
          div {
            height: 34px;
          }
          .ant-input-number {
            .ant-input-number-handler-wrap {
              height: 32px;
            }
          }
        }
      }
      .ant-radio-group.ant-radio-group-solid {
        display: flex;
        .ant-radio-button-wrapper {
          width: 37px;
          height: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 5px;
          border-radius: 2px;
        }
        .ant-radio-button-wrapper:first-child {
          margin-left: 0;
          width: 37px;
          height: 34px;
        }
      }
      .ant-btn {
        border-radius: 2px;
        height: 40px;
        font-weight: 700;
        margin-left: 0;
        margin-bottom: 12px;
      }
      .ant-form-item-label {
        line-height: 16px;
        margin-bottom: 5px;
      }
      .ant-form-item-control {
        line-height: 30px;
        padding: 0;
      }
      label {
        &.ant-form-item-required::before {
          display: none;
        }

        max-width: 96px;
        height: 16px;
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;
        color: @formEntries;
        &.ant-radio-button-wrapper {
          border-left: 1px solid @lightGrayMedium;
          color: @gray;
          text-transform: lowercase;
          font-size: 14px;
        }
        &.ant-radio-button-wrapper:not(:first-child)::before {
          background-color: @transparent;
          width: 36px;
        }
        &.ant-radio-button-wrapper-checked {
          background-color: @radioButtonBlue;
          border-color: @borderBlue;
          span {
            font-weight: 500;
            text-transform: lowercase;
            color: @brandAccentBlue;
            font-size: 14px;
          }
        }
        &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
          box-shadow: none;
          &:hover,
          &:active {
            border-color: @radioButtonBlue;
            background-color: @borderBlue;
          }
        }
        &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
          background-color: @radioButtonBlue !important;
        }
      }
      .ant-input {
        max-width: 120px;
      }
    }
  }
  .buttonDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    padding-right: 15px;
    .cancelButton {
      border-radius: 2px;
      padding-left: 20px;
      padding-right: 20px;
      height: 40px;
      margin-right: 7px;
      font-weight: 700;
      color: @lighterGray;
    }
    .cancelButtonHidden {
      display: none;
    }
  }
  .fuelCapacityEditDiv {
    display: flex;
    .fuelUnit {
      margin-left: 4px;
      margin-top: 2px;
    }
  }
  .errorDiv {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    .displayErrorDiv {
      display: flex;
      flex-direction: column;
    }
  }
  .errorMessage {
    color: @red;
  }
  .serialNumError {
    margin-left: -12px;
  }

  .errorDivHidden {
    display: none;
  }
}
