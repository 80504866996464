@imageUpload: #d9d9d9;
@switchChecked: #77bbfa;

@green: #4ab316;
@greenSubtle: #35b96d;
@greenThin: #34ba6d;
@greenOpaque: #e5ffef;
@greenMedium: rgba(74, 179, 22, 0.6);
@greenExtraLight: rgba(74, 179, 22, 0.1);

@amber: #f2a650;
@amberBright: #ffbb18;
@amberMedium: rgba(250, 140, 22, 0.6);
@amberLight: #f9efd6;
@amberExtraLight: rgba(250, 140, 22, 0.1);
@amberExtraLightTransparent: rgba(250, 140, 22, 0.05);

@red: #d01e27;
@redStrong: #bc0000;
@redMessage: #ac1717;
@redSubtle: #ff4347;
@redOpaque: #d4696f;
@redMedium: rgba(245, 34, 45, 0.6);
@veryLightRed: rgba(193, 66, 66, 0.3);
@greyRedish: rgba(193, 66, 66, 0.1);
@redLight: #f5d3d3;
@redThin: #ffeced;
@redExtraLight: #f7f7f7;
@redExtraLightTransparent: rgba(253, 247, 247, 0.5);

@blue: #126fd6;
@blueMedium: rgba(18, 111, 214, 0.6);
@mediumLightBlue: rgba(18, 111, 214, 0.3);
@blueExtraLight: rgba(18, 111, 214, 0.1);
@radioButtonBlue: rgba(18, 111, 214, 0.1);
@borderBlue: rgba(18, 111, 214, 0.3);
@slate: #314659;

@borderGray: #d9d9d9;
@lightBlue: #91d5ff;
@lightBlueLight: #e6f7ff;

@thinGray: #f7f7f7;
@lightGray: #d3d3d3;
@lightGrayMedium: rgba(211, 211, 211, 0.6);
@lightGrayLight: rgba(216, 216, 216, 0.2);
@mediumLightGray: rgba(211, 211, 211, 0.3);
@lightGrayExtraLight: rgba(211, 211, 211, 0.1);
@semiTransparentGray: rgba(219, 219, 219, 0.5);
@lightTransparentGray: rgba(230, 232, 237, 0.4);
@fontGray: #c0c0c0;

@gray: #808080;
@grayMedium: rgba(128, 128, 128, 0.6);
@newGray: rgba(250, 250, 250, 0.3);
@grayExtraLight: rgba(128, 128, 128, 0.1);
@lighterGray: #9b9b9b;
@mediumGray: #737373;
@darkGrey: #666666;
@borderGrey: #e8e8e8;
@greyLine: #dfe0eb;

@grayPlusSign: rgba(202, 202, 202, 0.3);

@grayBlue: #8c96a9;

@aqua: #13c2c2;
@aquaMedium: rgba(19, 194, 194, 0.6);
@aquaExtraLight: rgba(19, 194, 194, 0.1);

@black: #000000;
@titleBlack: #313745;
@titleDarkBlack: #181818;
@textBlack: #373737;
@formEntries: #181818;
@mxBlack: #646464;
@blackMedium85: rgba(0, 0, 0, 0.85);
@blackMedium: rgba(0, 0, 0, 0.65);
@blackMedium60: rgba(0, 0, 0, 0.6);
@blackLight: rgba(0, 0, 0, 0.1);
@blackExtraLight: rgba(0, 0, 0, 0.05);
@formBg: rgba(89, 89, 89, 0.05);

@white: #ffffff;
@offWhite: #fafafa;
@white80: rgba(255, 255, 255, 0.8);
@innerMenuWhite: rgba(255, 255, 255, 0.65);
@semiTransparentWhite: rgba(247, 248, 252, 0.5);
@snow: #fafbfd;
@whiteShadow: #dbe3ed;
@whiteLight: #f4f4f5;
@whiteThin: #fbfafe;
@whiteMedium55: rgba(232, 238, 244, 0.55);
@whiteMedium50: rgba(251, 252, 253, 0.5);
@whiteMedium40: #fbfcfc;
@whiteMedium55Solid: #f0f3f6;
@whiteBackground: #f3f4f6;
@borderGrey: #e6e8ed;

@brown: #7c7062;

@brandBoldBlue: #0c1a2f;
@brandExtraDarkBlue: #010306;
@brandExtraDarkBlueGradient: #10294b;
@brandDarkBlue: #001529;
@brandMediumBlue: #083976;
@brandBlue: #126fd6;
@contextMenuBlue: #182644;
@brandAccentBlue: #126fd6;
@brandAccentBlueDark: #398fff;
@brandLightGray: #e8e8e8;
@defectBlue: #1e589f;
@scheduledBlue: #470cfe;
@oopBlue: #baa3ff;
@llpBlue: #794efc;
@pkgBlue: #0085ff;

@transparent: rgba(0, 0, 0, 0);
@almostTransparent: rgba(0, 0, 0, 0.05);

@tripHighlightRow: #e6f7ff;
@tableAccent1: #e2faed;
@tableAccent2: #ffeac5;

@rowHighlighted: #e6f2ff;

// Overview Page colours

@overviewStatusTitle: #4d4d4d;
@overviewSettingsButton: #126fd6;
@oveviewShowTotals: #126fd6;
@buttonBorderColour: #e6e6e6;
@overviewBackgroundColour: rgba(255, 255, 255, 0);
@overviewBoxShadow: rgba(219, 227, 237, 0.41);

.textOverflow() {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
}

.textOverflowMulti(@line: 3, @bg: @white) {
  overflow: hidden;
  position: relative;
  line-height: 1.5em;
  max-height: @line * 1.5em;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
  &::before {
    background: @bg;
    content: '...';
    padding: 0 1px;
    position: absolute;
    right: 14px;
    bottom: 0;
  }
  &::after {
    background: @white;
    content: '';
    margin-top: 0.2em;
    position: absolute;
    right: 14px;
    width: 1em;
    height: 1em;
  }
}

:global {
  body {
    background: #f6f8fb;
  }
}
// mixins for clearfix
// ------------------------
.clearfix() {
  zoom: 1;
  &::before,
  &::after {
    content: ' ';
    display: table;
  }
  &::after {
    clear: both;
    visibility: hidden;
    font-size: 0;
    height: 0;
  }
}

// Helper LESS Mixin to create gradients as background
.gradient(@type; @colors; @dir: 0deg; @prefixes: webkit, moz, ms, o; @index: length(@prefixes)) when (@index > 0) {
  .gradient(@type; @colors; @dir; @prefixes; (@index - 1));

  @prefix: extract(@prefixes, @index);
  @dir-old: 90 - (@dir);

  background-image: ~'-@{prefix}-@{type}-gradient(@{dir-old}, @{colors})';
  & when ( @index = length(@prefixes) ) {
    background-image: ~'@{type}-gradient(@{dir}, @{colors})';
  }
}

.placeholder(@rules) {
  &::-webkit-input-placeholder {
    @rules();
  }
  &:-moz-placeholder {
    @rules();
  }
  &::-moz-placeholder {
    @rules();
  }
  &:-ms-input-placeholder {
    @rules();
  }
}
