@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.buttonDiv {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 8px;
}

.crewTableDiv {
  :global {
    .ant-btn {
      font-weight: 500;
      border-radius: 15px;
    }
    .ant-table-thead {
      tr {
        th {
          &.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
            background-color: @white;
          }

          background-color: @white;
          .ant-table-header-column {
            background-color: @white;
            .ant-table-column-sorters {
              &:hover {
                &::before {
                  background-color: @white;
                }
              }
            }
          }
        }
      }
    }
    .ant-table-placeholder {
      border: 1px solid @borderGrey;
    }
  }
}

.modalContentWrapper {
  height: auto;
  .modalTitle {
    font-weight: 500;
    color: #242d41;
    padding-bottom: 12px;
  }
  .modalMessage {
    line-height: 1.29;
    color: rgba(36, 45, 65, 0.7);
  }
  .modalButtonWrapper {
    display: flex;
    justify-content: flex-start;
    margin-top: 28px;
    .submitButton {
      margin-right: 12px;
    }
    .cancelButton {
      border-radius: 16px;
    }
  }
}

.emptyText {
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  min-height: 449px;
  img {
    height: 115px;
  }
  span {
    color: rgba(36, 45, 65, 0.5);
    font-size: 13px;
    padding-top: 8px;
    letter-spacing: 0.42px;
  }
}

.emptyTextNoAPU {
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  min-height: 389px;
  img {
    height: 115px;
  }
  span {
    color: rgba(36, 45, 65, 0.5);
    font-size: 13px;
    padding-top: 8px;
    letter-spacing: 0.42px;
  }
}

.avatarContainer {
  display: inline-block;
  margin-right: 5px;
}

.nameContainer {
  display: inline-block;
}

.editableRowOperations {
  text-align: right;
}

.noWrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (max-width: @screen-md) {
  .emptyText {
    min-height: 200px;
  }
  .emptyTextNoAPU {
    min-height: 200px;
  }
}

@media screen and (max-width: @screen-xxl) {
  .emptyText {
    min-height: 571px;
  }
  .emptyTextNoAPU {
    min-height: 511px;
  }
}

@media screen and (max-width: 1200px) {
  .emptyText {
    min-height: 571px;
  }
  .emptyTextNoAPU {
    min-height: 511px;
  }
}

@media screen and (max-width: 1199px) {
  .emptyText {
    min-height: 200px;
  }
  .emptyTextNoAPU {
    min-height: 200px;
  }
}

@media screen and (width: 1600px) {
  .emptyText {
    min-height: 449px;
  }
  .emptyTextNoAPU {
    min-height: 389px;
  }
}
