@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.periodTitle {
  color: @titleDarkBlack;
  border-bottom: 1px @borderGrey solid;
  font-weight: 600;
  margin-bottom: 15px;
  padding-bottom: 4px;
  &.periodTitleerror {
    color: @redMessage;
  }
  &.periodTitlewarning {
    color: @amber;
  }
  &.periodTitlesuccess {
    color: @greenSubtle;
  }
}

:global {
  .ant-empty-description {
    font-size: 13px;
    line-height: 1.38;
    letter-spacing: 0.2px;
    color: rgba(36, 45, 65, 0.6);
  }
}

.periodDate {
  margin: 10px 0;
  color: @titleDarkBlack;
  letter-spacing: -0.09px;
  font-weight: 500;
  &.periodDatesuccess {
    color: @greenSubtle;
  }
  &.periodDatewarning {
    color: @amber;
  }
  &.periodDateerror {
    color: @redMessage;
  }
}

.emptyMessage {
  margin: 50px 0;
}
