@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.rowHeaderWrapper {
  margin-top: 37px;
}

.rowHeader {
  display: flex;
  align-items: center;
  .toggleUpliftButton {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.05px;
    color: #126fd6;
    cursor: pointer;
    border: none;
    background-color: transparent;
  }
  .cancelButton {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.46px;
    text-align: center;
    color: #126fd6;
    margin: 0 25px;
    cursor: pointer;
    border: none;
    background-color: transparent;
  }
  .addUpliftButton {
    width: 122px;
    height: 40px;
    border-radius: 20px;
    border: solid 1px #126fd6;
    background-color: #126fd6;
    color: white;
    cursor: pointer;
  }
}

.title {
  width: 97px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.06px;
  color: #181818;
}

.dataTitle {
  font-size: 12px;
  color: #b9b9b9;
}

.dataTitleJust {
  font-size: 12px;
  color: #b9b9b9;
  margin-top: 25px;
}

.data {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.46px;
  color: #646464;
}

.fuelInput {
  :global {
    .ant-input-number-handler-wrap {
      display: none;
    }
  }
  .dataInput {
    width: 90px;
    height: 40px;
    border-radius: 2px;
    border: solid 1px #d9d9d9;
    padding-left: 5px;
    :global {
      .ant-input-number {
        width: 90px;
        height: 40px;
        border-radius: 2px;
        border: solid 1px #d9d9d9;
        padding-left: 5px;
        .ant-input-number-handler-wrap {
          display: none;
        }
      }
      .ant-input-number-input {
        width: 90px;
        height: 40px;
      }
    }
  }
}

.textbox {
  width: 100%;
  height: auto;
  min-height: 40px;
  border-radius: 2px;
  border: solid 1px #d9d9d9;
  padding-left: 5px;
  color: #646464;
  outline: none;
}
.textbox:focus {
  box-shadow: 0 0 0 2px rgba(18, 111, 214, 0.2);
}
