@import '~antd/lib/style/themes/default.less';
@import '../../utils/utils.less';

.drawerWrapper {
  padding: 26px 46px;
}

.editTripForm,
.editTripFormMargin {
  background-color: #f4f4f5;
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.editTripFormMargin {
  padding-bottom: 110px;
}

.submitButtonWrapper {
  margin-right: 20px;
}

.carriedForwardOverride {
  width: 55px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #d9d9d9;
  padding: 11px;
}

.sectionWrapper,
.sectionWrapperFinal {
  margin-bottom: 37.5px;
  .headerWrapper {
    display: flex;
    align-items: center;
    img {
      width: 16px;
      height: 16px;
    }
    .header,
    .headerLong {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.06px;
      color: #181818;
      padding-right: 10px;
      margin-left: 5px;
    }
    .headerLong {
      width: 130px;
    }
    .headerLine {
      width: 100%;
      height: 1px;
      background-color: #dfe0eb;
    }
  }
}
.sectionWrapperFinal {
  margin-bottom: 24px;
}

.headerLong {
  width: 115px;
}

.section {
  .sectionRow {
    margin: 12.5px 0;
    .sectionCol,
    .sectionColEngine {
      margin: 12.5px 0;
      .dataSection {
        .title {
          font-size: 12px;
          color: #b9b9b9;
          width: 137px;
          line-height: normal;
        }
        .data {
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.46px;
          color: #646464;
        }
        :global {
          .ant-input {
            width: 110px;
            height: 40px;
            border-radius: 2px;
            border: solid 1px #d9d9d9;
            .ant-input-number-handler-wrap {
              display: none;
            }
          }
        }
      }
    }
    :global {
      .ant-form-explain {
        display: none;
      }
    }
  }
}

.formInput {
  width: 110px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #d9d9d9;
  padding: 11px;
}

.overrideNotice {
  font-size: 12px;
  padding: 6px 10px;
  width: 285px;
}

.clearButton {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.05px;
  color: #126fd6;
  margin-left: 10px;
  cursor: pointer;
}

.calculatedWrapper {
  line-height: normal;
  .calculated,
  .calculatedNumber {
    width: 85px;
    height: 12px;
    font-size: 10px;
    color: #9f9f9f;
  }
  .calculatedNumber {
    font-weight: 500;
    color: #646464;
  }
}

.sectionSpacer {
  margin-right: 0;
}

.columnHeading {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.06px;
  color: #181818;
  margin-top: 12.5px;
}

.sectionColEngine {
  display: flex;
}

.dataWrapper {
  display: flex;
}

.dataSectionEngine {
  padding-bottom: 15px;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 78px;
  background-color: #f4f4f5;
  .cancelButton {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.46px;
    text-align: center;
    color: #646464;
    margin-right: 15px;
    cursor: pointer;
    border: none;
    background-color: transparent;
  }
  .saveButton {
    width: 166px;
    height: 40px;
    border-radius: 20px;
    background-color: #126fd6;
    color: white;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.05px;
    text-align: center;
    cursor: pointer;
  }
}

.mobileButtonDiv {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: #f4f4f5;
  .buttonWrapper {
    margin: 40px 0;
  }
}

.invisibleDiv {
  width: 165px;
  height: 84px;
  color: @red;
}

.invisibleWarningDiv {
  width: 165px;
  height: 46px;
}

.warningMessage {
  color: @amberBright;
}

@media screen and (max-width: @screen-xs) {
  .drawerWrapper {
    padding: 26px 15px;
  }
  .sectionWrapper {
    .headerWrapper {
      .headerLong {
        width: 210px;
      }
    }
  }
}
