@import '../../utils/utils.less';
@import '~antd/lib/style/themes/default.less';

.groupTitle {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: -0.08px;
  color: @titleDarkBlack;
  margin-bottom: 8px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  &:first-child {
    margin-top: 0;
  }
}

.clearFilter {
  color: @brandAccentBlue;
  font-weight: 300;
  cursor: pointer;
  text-transform: none;
  font-size: 14px;
  height: auto;
  padding: 0;
  line-height: 1;
  margin-top: -2px;
}

.singleFilter {
  margin: 6px 0;
  padding: 2px 0;
  letter-spacing: -0.09px;
  color: @darkGrey;
  height: unset;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  &:not(.singleFilterBoolean) {
    cursor: pointer;
  }
  &:focus {
    color: @darkGrey;
  }
  span {
    margin-left: 0;
  }
  :global {
    .anticon + span {
      margin-left: 0;
    }
  }
  .closeIcon {
    position: absolute;
    top: 2px;
    left: 5px;
    font-size: 10px;
    color: @white;
    cursor: pointer;
    padding: 5px;
    display: none;
  }
}

.singleFilterActive {
  background: @brandAccentBlue;
  padding: 2px 25px;
  margin-left: -25px;
  color: @white;
  border-radius: 25px;
  width: calc(100% + 25px);
  height: unset;
  .closeIcon {
    display: block;
  }
  &.singleFilterDisabled {
    pointer-events: all;
  }
  &:hover {
    background: @brandAccentBlue;
    color: @white;
  }
  &:focus {
    background: @brandAccentBlue;
    color: @white;
  }
  span {
    margin-left: 0;
  }
}

.singleFilterDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.plusIcon {
  color: @brandAccentBlue;
  font-size: 10px;
  position: relative;
  top: -1px;
  margin-left: 4px;
  overflow: auto;
  svg {
    shape-rendering: auto !important; // override antd styling
  }
}

.singleFilterChild {
  margin-left: 10px;
  font-size: 12px;
  &.singleFilterActive {
    padding-left: 35px;
    margin-left: -25px;
    .closeIcon {
      top: 1px;
    }
  }
}

@media screen and (max-width: @screen-lg) {
  .singleFilter {
    :global {
      .anticon + span {
        margin-left: 0;
      }
    }
  }
}
